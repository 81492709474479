import React from 'react'

const TheChangeSVG = props => {
  return (
    <svg
      id='Layer_1'
      data-name='Layer 1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 191.28 14.48'
    >
      <defs></defs>
      <path
        className='cls-1'
        fill='#a4a4a4'
        d='M10.34,1.17,10,3.41H6.94V15H4.06V3.41H.82V1.17Z'
        transform='translate(-0.82 -0.85)'
      />
      <path
        className='cls-1'
        fill='#a4a4a4'
        d='M28.32,15V8.93H24.48V15H21.6V1.17h2.88V6.65h3.84V1.17H31.2V15Z'
        transform='translate(-0.82 -0.85)'
      />
      <path
        className='cls-1'
        fill='#a4a4a4'
        d='M51.28,1.17,51,3.27H46.56V6.93h3.86V9H46.56v3.88h4.72V15h-7.6V1.17Z'
        transform='translate(-0.82 -0.85)'
      />
      <path
        className='cls-1'
        fill='#a4a4a4'
        d='M84.62,1.22a6.38,6.38,0,0,1,1.74,1.11L85,4a4.17,4.17,0,0,0-1.1-.71A3,3,0,0,0,82.72,3q-2.77,0-2.78,5a7.52,7.52,0,0,0,.74,3.8,2.31,2.31,0,0,0,2.06,1.2A2.61,2.61,0,0,0,84,12.79a6.84,6.84,0,0,0,1.18-.8l1.34,1.62a5.37,5.37,0,0,1-1.7,1.24,5,5,0,0,1-2.22.48,5.42,5.42,0,0,1-3-.81,5.28,5.28,0,0,1-2-2.44,10.29,10.29,0,0,1-.7-4,9.73,9.73,0,0,1,.72-4,5.44,5.44,0,0,1,2-2.44,5.69,5.69,0,0,1,5-.45Z'
        transform='translate(-0.82 -0.85)'
      />
      <path
        className='cls-1'
        fill='#a4a4a4'
        d='M104.6,15V8.93h-3.84V15H97.88V1.17h2.88V6.65h3.84V1.17h2.88V15Z'
        transform='translate(-0.82 -0.85)'
      />
      <path
        className='cls-1'
        fill='#a4a4a4'
        d='M126.66,15,126,11.89h-3.8L121.5,15h-2.92l3.8-13.84h3.48L129.64,15Zm-4-5.24h2.84l-1.42-6.46Z'
        transform='translate(-0.82 -0.85)'
      />
      <path
        className='cls-1'
        fill='#a4a4a4'
        d='M150.3,15h-3.66L142.92,4a2.82,2.82,0,0,1,0,.44c.11.93.19,1.78.25,2.53s.09,1.63.09,2.63V15h-2.56V1.17h3.6l3.78,11.08a34.49,34.49,0,0,1-.38-4.92V1.17h2.56Z'
        transform='translate(-0.82 -0.85)'
      />
      <path
        className='cls-1'
        fill='#a4a4a4'
        d='M170.45,1.28a6.58,6.58,0,0,1,1.87,1.23L170.86,4a5.28,5.28,0,0,0-1.26-.78A3.41,3.41,0,0,0,168.28,3q-3.06,0-3.06,5.1a12.8,12.8,0,0,0,.29,3.05,3,3,0,0,0,.86,1.57,2.29,2.29,0,0,0,1.49.46,3.35,3.35,0,0,0,1.76-.48V9.21h-1.68l-.28-2.08h4.72v6.8a8.46,8.46,0,0,1-2.14,1,7.53,7.53,0,0,1-2.34.37,5.07,5.07,0,0,1-4.25-1.8,8.78,8.78,0,0,1-1.41-5.46,9.15,9.15,0,0,1,.78-4,5.51,5.51,0,0,1,2.13-2.44,5.77,5.77,0,0,1,3-.81A5.67,5.67,0,0,1,170.45,1.28Z'
        transform='translate(-0.82 -0.85)'
      />
      <path
        className='cls-1'
        fill='#a4a4a4'
        d='M192.1,1.17l-.3,2.1h-4.42V6.93h3.86V9h-3.86v3.88h4.72V15h-7.6V1.17Z'
        transform='translate(-0.82 -0.85)'
      />
    </svg>
  )
}

export default TheChangeSVG
