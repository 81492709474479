import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import { colors, zIndex, siteBreakPoints } from '../../../consts/styleConsts'
import { removeSelect, removeBackgroundOnClick } from '../../../styled/extends'
import { removeImageDrag } from '../../../styled/extends'

const { primary } = colors
const { darkGrey2, grey3 } = colors.grey
const { headerIndex } = zIndex
const darkBackground = colors.background.dark
const { xxLarge, small } = siteBreakPoints

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  opacity: 0;
  left: 0;
  top: 0;
  pointer-events: none;
  transition: 0.4s ease;
  background: ${darkBackground};
  z-index: ${headerIndex};
  ${({ isMenuOpened }) =>
    isMenuOpened &&
    css`
      opacity: 1;
      pointer-events: auto;
    `}
  ${removeSelect};
`
export const MenuWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: calc(100vw - 110px);
  transform: translate(-50%, -50%);
  @media only screen and (max-width: ${xxLarge}px) {
    width: 90%;
  }
`
export const Menu = styled.ul`
  display: flex;
  @media only screen and (max-width: ${xxLarge}px) {
    flex-direction: column;
  }
`
export const MenuItem = styled.li`
  display: flex;
  align-items: center;
  flex-grow: 1;
  @media only screen and (max-width: ${xxLarge}px) {
    justify-content: center;
    margin: 1rem 0;
  }
  @media only screen and (max-width: ${small}px) {
    justify-content: center;
    margin: 0.7rem 0;
  }
`
export const MenuLink = styled(Link)`
  font-size: 3rem;
  line-height: 4rem;
  font-family: Plex-Bold;
  color: ${darkGrey2};
  @media only screen and (max-width: ${small}px) {
    text-aling: center;
    font-size: 1.75rem;
    line-height: 2.31rem;
  }
`
export const MenuCircle = styled.div`
  width: 1rem;
  height: 1rem;
  border: 4px solid ${darkGrey2};
  border-radius: 50%;
  opacity: 0.5;
`
export const HeaderWrapper = styled.div`
  position: fixed;
  width: calc(100vw - 70px);
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  color: white;
  align-items: center;
  z-index: ${headerIndex};
  pointer-events: none;
  & > div {
    pointer-events: auto;
  }
  @media only screen and (max-width: ${small}px) {
    width: calc(100vw - 30px);
    top: 15px;
    height: 28px;
  }
`
export const TitleWrapper = styled.div``
export const Title = styled(Link)`
  display: block;
  user-select: none;
  width: 7.95rem;
  height: 1.18rem;
  ${removeImageDrag};
  & svg {
    width: 100%;
  }
  @media only screen and (max-width: ${small}px) {
    position: relative;
    top: 4px;
    width: 7.5rem;
  }
`
export const EyesOpenedWrapper = styled.div`
  transform: translateX(50%);
  position: absolute;
  right: 50%;
  ${({ isMenuOpened }) =>
    isMenuOpened &&
    css`
      opacity: 0;
    `}
  @media only screen and (max-width: ${small}px) {
    right: 85px;
  }
`
export const EyesOpenedText = styled.span`
  text-transform: uppercase;
  font-family: Plex-Bold;
  color: ${primary};
  font-size: 0.54rem;
  line-height: 0.81rem;
  letter-spacing: 0px;
  border-bottom: 2px solid ${primary};
  border-top: 2px solid ${primary};
  padding: 4px 0;
  @media only screen and (max-width: ${small}px) {
    font-size: 10px;
  }
`
export const MenuTogglerWrapper = styled.div`
  ${removeBackgroundOnClick};
  @media only screen and (max-width: ${small}px) {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    right: -5px;
  }
`
export const MenuToggler = styled.div`
  cursor: pointer;
  width: 40px;
  height: 24px;
  position: relative;
  right: 8px;
  @media only screen and (max-width: ${small}px) {
    width: 33px;
    height: 23px;
  }
  & div {
    position: absolute;
    right: 0;
    background: ${grey3};
    height: 2px;
    transition: 0.4s ease;
  }
  ${({ isMenuOpened }) =>
    !isMenuOpened &&
    css`
      &:hover {
        & div:nth-child(1) {
          transform: translateY(-2px);
        }
        & div:nth-child(3) {
          transform: translateY(2px);
        }
      }
    `}
  ${({ isMenuOpened }) =>
    isMenuOpened &&
    css`
      & div:nth-child(1) {
        width: 80%;
        transform: rotate(-45deg);
        top: 50%;
      }
      & div:nth-child(2) {
        right: 30px;
        opacity: 0;
      }
      & div:nth-child(3) {
        width: 80%;
        transform: rotate(45deg);
        bottom: 41%;
      }
    `}
`
export const TogglerLine1 = styled.div`
  width: 100%;
`
export const TogglerLine2 = styled.div`
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
`
export const TogglerLine3 = styled.div`
  width: 70%;
  bottom: 0;
`
