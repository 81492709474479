import React from 'react'

import {
  Wrapper,
  Container,
  Title,
  Text,
  Eye,
  Button,
  FiexBottomWrapper,
  LineLeft,
  LineRight
} from './styled'

import EyeSvg from '../../../images/eye-opened-main-page.svg'

const YouLearnedSoMuchWrapper = () => {
  return (
    <Wrapper>
      <Container>
        <Title>You learned so much!</Title>
        <Eye src={EyeSvg} />
        <Text>
          You’ve learned so much information about the climate change for the
          earth. Now you can check how to prevent them here…
        </Text>
        <LineLeft />
        <LineRight />
      </Container>
      <FiexBottomWrapper>
        <Button to='what-you-can-do'>What you can do ?</Button>
      </FiexBottomWrapper>
    </Wrapper>
  )
}

export default YouLearnedSoMuchWrapper
