import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import TheChangeSVG from '../../Shared/svg/TheChangeSVG'
import {
  HeaderWrapper,
  TitleWrapper,
  Title,
  MenuTogglerWrapper,
  MenuToggler,
  TogglerLine1,
  TogglerLine2,
  TogglerLine3,
  Overlay,
  MenuWrapper,
  Menu,
  MenuItem,
  MenuLink,
  MenuCircle,
  EyesOpenedWrapper,
  EyesOpenedText
} from './styled'

const Header = ({ isPage }) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false)

  const toggleMenu = () => setIsMenuOpened(prevValue => !prevValue)

  const { eyesState } = useSelector(state => state)

  const getViewedEyesPercent = () => {
    const viewdEyesArr = Object.values(eyesState).map(({ viewed }) => viewed)
    const viewedEyesPercent = Math.round(
      (100 / viewdEyesArr.length) * viewdEyesArr.filter(v => v).length
    )
    return viewedEyesPercent
  }

  useEffect(() => {
    const onKeyPress = ({ keyCode }) =>
      keyCode === 27 ? setIsMenuOpened(false) : null
    document.addEventListener('keydown', onKeyPress, false)
    return () => document.removeEventListener('keydown', onKeyPress, false)
  }, [])

  return (
    <>
      <Overlay isMenuOpened={isMenuOpened}>
        <MenuWrapper>
          <Menu>
            <MenuItem>
              <MenuLink to='/' onClick={toggleMenu}>
                HOME
              </MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuCircle />
            </MenuItem>
            <MenuItem>
              <MenuLink to='/what-you-can-do' onClick={toggleMenu}>
                WHAT YOU CAN DO?
              </MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuCircle />
            </MenuItem>
            <MenuItem>
              <MenuLink to='/about' onClick={toggleMenu}>
                ABOUT
              </MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuCircle />
            </MenuItem>
            <MenuItem>
              <MenuLink to='/sources' onClick={toggleMenu}>
                SOURCES
              </MenuLink>
            </MenuItem>
          </Menu>
        </MenuWrapper>
      </Overlay>

      <HeaderWrapper>
        <TitleWrapper>
          <Title to='/'>
            <TheChangeSVG />
          </Title>
        </TitleWrapper>

        {!isPage && !isMenuOpened ? (
          <EyesOpenedWrapper>
            <EyesOpenedText>{getViewedEyesPercent()}% opened</EyesOpenedText>
          </EyesOpenedWrapper>
        ) : null}

        <MenuTogglerWrapper onClick={toggleMenu}>
          <MenuToggler isMenuOpened={isMenuOpened}>
            <TogglerLine1 />
            <TogglerLine2 />
            <TogglerLine3 />
          </MenuToggler>
        </MenuTogglerWrapper>
      </HeaderWrapper>
    </>
  )
}

export default Header
