import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { clearCoordinates } from '../../../redux/mapRecenterState/actions'
import GrainBG from '../GrainBG/GrainBG'
import Header from '../Header/Header'
import SocialShareMeta from '../../Shared/SocialShareMeta/SocialShareMeta'

const MainLayout = ({ children, isPage }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (isPage) {
      dispatch(clearCoordinates)
    }
  }, [dispatch, isPage])
  return (
    <>
      <SocialShareMeta />
      <GrainBG />
      <Header isPage={isPage} />
      {children}
    </>
  )
}

export default MainLayout
