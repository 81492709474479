import styled from 'styled-components'

import { Link } from 'gatsby'

import { buttonStyle } from '../../../styled/pageStyle'
import { colors, siteBreakPoints } from '../../../consts/styleConsts'

const { darkGrey2, grey3 } = colors.grey
const { small, xSmall, large, heightMedium } = siteBreakPoints
const height670 = 670

export const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  @media screen and (max-height: ${height670}px) and (min-width: ${small}px) {
    position: relative;
    height: 670px;
  }
`
export const Container = styled.div`
  width: 100%;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  & > * {
    display: block;
  }
`
export const Title = styled.span`
  font-family: Plex-Bold;
  font-size: 2rem;
  line-height: 2.59rem;
  letter-spacing: 5px;
  color: ${darkGrey2};
  text-transform: uppercase;
  text-align: center;

  @media screen and (max-width: ${small}px) {
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: 2.37px;
  }
`
export const Text = styled.span`
  font-family: Plex-Light;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: ${darkGrey2};
  max-width: 27.95rem;
  @media screen and (max-width: ${small}px) {
    font-size: 1.12rem;
    line-height: 1.43rem;
  }
  @media screen and (max-width: ${xSmall}px) {
    font-size: 1rem;
    padding: 0 0.62rem;
  }
`
export const Eye = styled.img`
  max-width: 23rem;
  margin-top: 50px;
  margin-bottom: 40px;
  position: relative;
  top: -20px;
  @media screen and (max-width: ${small}px) {
    width: 15rem;
    top: -10px;
    left: 8px;
  }
  @media screen and (max-width: ${xSmall}px) {
    width: 15rem;
    top: 0px;
    left: 6px;
  }
`
export const FiexBottomWrapper = styled.div`
  position: fixed;
  bottom: 4rem;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  @media screen and (max-height: ${heightMedium}px) {
    bottom: 2rem;
  }
  @media screen and (max-height: ${height670}px) and (min-width: ${small}px) {
    position: absolute;
  }
`
export const Button = styled(Link)`
  ${buttonStyle}
`
const Line = styled.div`
  position: absolute;
  content: '';
  top: 50%;
  width: 11.86rem;
  height: 1px;
  background: ${grey3};
  @media screen and (max-width: ${large}px) {
    width: 5rem;
  }
  @media screen and (max-width: ${small}px) {
    width: 2.5rem;
  }
`
export const LineLeft = styled(Line)`
  left: 0;
`
export const LineRight = styled(Line)`
  right: 0;
`
