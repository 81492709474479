import styled, { css } from 'styled-components'

import { colors, siteBreakPoints } from '../consts/styleConsts'
const { lightBlue } = colors.secondary
const { grey } = colors.grey
const { primary } = colors
const { dark4 } = colors.background

export const containerWidth = 1360

export const containerWidthTablet = 80

export const containerPadding = 140
export const containerPaddingTablet = 100
export const containerPaddingMobile = 20

export const containerBreakPoint = 1400
export const containerBreakPointMobile = 700

export const tablet = 1200
export const mobile = 600
const { small } = siteBreakPoints

export const PageWrapperStyle = styled.div`
  overflow: hidden;
  position: relative;
  width: 100vw;
  overflow-x: hidden;
  min-height: 100vh;
  background: ${dark4};
  padding-top: 8.4rem;
  @media screen and (max-width: ${small}px) {
    padding-top: 5.62rem;
  }
`
export const PageNameComponent = styled.span`
  padding-bottom: 1.13rem;
  display: inline-block;
  position: relative;
  color: ${grey};
  font-family: Plex-Bold;
  font-size: 1.27rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.68rem;
  text-transform: uppercase;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 2px;
    background: ${primary};
  }
`
export const PageName = styled.span`
  @media screen and (max-width: ${tablet}px) {
    font-size: 24px;
  }
  @media screen and (max-width: ${mobile}px) {
    padding-bottom: 20px;
    line-height: 20px;
    font-size: 16px;
  }
`
export const PageTitleWrapperStyle = styled.div`
  margin-top: 3.4rem;
  @media screen and (max-width: ${small}px) {
    margin-top: 2.37rem;
  }
`
export const PageTitleStyle = styled.span`
  position: relative;
  color: ${primary};
  font-family: Plex-Bold;
  font-size: 4.54rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 4.45rem;
  text-transform: uppercase;
  display: block;
  @media screen and (max-width: ${small}px) {
    font-size: 2.12rem;
    line-height: 2.5rem;
  }
`
export const buttonStyle = css`
  border: 1px solid ${lightBlue};
  color: ${lightBlue};
  padding: 0.68rem 2.04rem;
  font-family: Plex-Bold;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.31rem;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  transition: 0.2s ease;
  &:hover {
    color: white;
    background: ${lightBlue};
  }
  @media screen and (max-width: ${small}px) {
    font-size: 1rem;
    padding: 0.81rem 2.18rem;
    min-width: 10rem;
    text-align: center;
  }
`
