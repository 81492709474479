import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { Helmet } from 'react-helmet'
import Facebook from '../../../images/socials/Facebook.jpg'
import Twitter from '../../../images/socials/Twitter.jpg'

const SocialShareMeta = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          baseURL
        }
      }
    }
  `)
  const siteBaseURL = data.site.siteMetadata.baseURL

  return (
    <Helmet>
      <title>The Change</title>
      <meta
        name='description'
        content='Open your eyes to the climate change'
      ></meta>
      <meta property='og:title' content='The Change' />
      <meta
        property='og:description'
        content='Open your eyes to climate change.'
      />
      <meta property='og:image' content={`${siteBaseURL}${Facebook}`} />
      <meta property='og:image:width' content='1200' />
      <meta property='og:image:height' content='630' />
      <meta property='og:type' content='website' />

      <meta name='twitter:title' content='The Change' />
      <meta
        name='twitter:description'
        content='Open your eyes to climate change.'
      />
      <meta name='twitter:image' content={`${siteBaseURL}${Twitter}`} />
      <meta name='twitter:card' content='summary_large_image' />
    </Helmet>
  )
}

export default SocialShareMeta
