import styled, { keyframes } from 'styled-components'

import grainImage from '../../../images/grain-background.png'

const grainAnimation = keyframes`
  0% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(-5%, -5%);
  }
  20% {
    transform: translate(-10%, 5%);
  }
  30% {
    transform: translate(5%, -10%);
  }
  40% {
    transform: translate(-5%, 15%);
  }
  50% {
    transform: translate(-10%, 5%);
  }
  60% {
    transform: translate(15%, 0);
  }
  70% {
    transform: translate(0, 10%);
  }
  80% {
    transform: translate(-15%, 0);
  }
  90% {
    transform: translate(10%, 5%);
  }
  100% {
    transform: translate(5%, 0);
  }
`
export const ComponentWrapper = styled.div`
  position: fixed;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  width: 200%;
  height: 200vh;
  background: transparent url(${grainImage}) repeat 0 0;
  background-repeat: repeat;
  animation: ${grainAnimation} 0.2s infinite;
  opacity: 0.9;
  visibility: visible;
  pointer-events: none;
  z-index: 100;
`
