import React from 'react'

import MainLayout from '../components/LayoutsRelated/Layouts/MainLayout'
import YouLearnedSoMuchWrapper from '../components/PageRelated/YouLearnedSoMuchWrapper/YouLearnedSoMuchWrapper'

const YouLearnedSoMuch = () => {
  return (
    <MainLayout isPage>
      <YouLearnedSoMuchWrapper />
    </MainLayout>
  )
}

export default YouLearnedSoMuch
